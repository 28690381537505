import mixpanel from 'mixpanel-browser';

mixpanel.init(import.meta.env.MIX_MIXPANEL_TOKEN, {
  debug: import.meta.env.MIX_MIXPANEL_DEBUG,
});

export default {
  install: (app, { userId }) => {
    if (userId !== null) {
      mixpanel.identify(userId);
      mixpanel.register_once({ $user_id: userId });
    }
    app.config.globalProperties.$mixpanel = mixpanel;
  },
};
